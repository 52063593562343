<template>
  <div class="page">
    <div class="header">
      <p class="date">
        {{ this.date.year }} {{ this.date.week }} {{ this.date.time }}
      </p>
      <p class="title">智慧抽油监控系统</p>
    </div>
    <form class="loginForm" @submit.prevent="confirmData">
      <div class="user">
        <input
          type="text"
          name="phone"
          v-model="loginInfo.phone"
          required
          placeholder="请输入手机号"
        />
        <div class="msg">{{ msg.phone }}</div>
      </div>
      <div class="password">
        <input
          type="password"
          name="password"
          v-model="loginInfo.password"
          required
          placeholder="请输入密码"
        />
        <div class="msg">{{ msg.password }}</div>
      </div>
      <div class="submit">
        <button type="submit" @click="login">登陆</button>
      </div>
    </form>
  </div>
</template>

<script>
import LoginJs from 'network/login/login.js'

export default {
  name: 'Login',
  data: () => {
    return {
      loginInfo: {
        phone: '',
        password: ''
      },
      msg: {
        phone: '',
        password: ''
      },
      date: {
        year: '',
        week: '',
        time: ''
      }
    }
  },
  methods: {
    /**
     * 监听事件
     */
    login() {
      if (this.confirmData()) {
        this.userLogin()
      }
    },
    /**
     * 网络连接
     */
    // 用户登陆
    userLogin() {
      this.loginInfo.password = this.$md5(this.loginInfo.password)
      LoginJs.userLogin(this.loginInfo).then((res) => {
        if (res.code == 0) {
          // 将登陆信息持久化存储
          const loginInfo = { session_id: res.session_id }
          this.$store.dispatch('setLoginAsync', res.data)
          sessionStorage.setItem('loginInfo', JSON.stringify(loginInfo))
          this.$router.push('/NationalMachine')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 其它方法
     */

    // 判断验证
    confirmData() {
      // const obj = this.loginInfo
      // const phoneRule = /^1[34578]\d{9}$/
      // const pwdRule = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{6,10}$/
      // if (obj.phone.length == '' || !phoneRule.test(obj.phone)) {
      //   this.msg.phone = '请核对手机号'
      //   return false
      // } else {
      //   this.msg.phone = ''
      // }
      // if (!pwdRule.test(obj.password)) {
      //   this.msg.password = '请核对密码'
      //   return false
      // } else {
      //   this.msg.password = ''
      // }
      return true
    },
    setDate() {
      this.date.year = this.$moment().format('LL')
      this.date.week = this.currentDay = this.$moment().format('dddd')
      this.date.time = this.$moment().format('a HH:mm')
    }
  },
  mounted() {
    this.setDate()
  },
  created() {
  }
}
</script>

<style lang="less" scoped>
@import '~assets/css/normalize.css';
@import '~assets/css/common.less';
.page {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('https://pump7niu.ahsanen.com/img/login/bg.png');
  background-size: cover;
  .header {
    width: 100%;
    height: auto;
    .date {
      position: absolute;
      left: 26px;
      top: 17px;
      width: auto;
      height: auto;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    .title {
      position: absolute;
      left: 50%;
      top: 23px;
      transform: translate(-50%);
      width: 319px;
      height: auto;
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansCN-Bold;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(0deg, #d7d7d7 0%, #ffffff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .loginForm {
    position: relative;
    width: 655px;
    height: 514px;
    background-image: url('https://pump7niu.ahsanen.com/img/login/form.png');
    background-size: cover;
    .user {
      position: absolute;
      width: 50%;
      top: 34.5%;
      left: 30%;
      input {
        width: 100%;
      }
    }
    .password {
      position: absolute;
      width: 50%;
      top: 54.5%;
      left: 30%;
      input {
        width: 100%;
      }
    }
    .msg {
      margin-top: 15px;
      color: red;
    }
    .submit {
      position: absolute;
      bottom: 90px;
      left: 118px;
      width: 429px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url('https://pump7niu.ahsanen.com/img/login/btn-bg.png');
      background-size: cover;
      button {
        width: 100%;
        height: 100%;
        letter-spacing: 10px;
        font-size: 25px;
        font-family: Source Han Sans CN;
        border: none;
        color: #ffffff;
        background: transparent;
      }
    }
    input {
      width: 100%;
      height: 44px;
      background: transparent;
      font-size: 19px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      background: red;
      background: transparent;
      &::placeholder {
        letter-spacing: 2px;
        font-size: 19px;
        font-family: Source Han Sans CN;
        color: #ffffff;
      }
    }
  }
}
</style>
