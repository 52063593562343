import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'; // 导入模块
import md5 from 'js-md5';
import './utils/flexible'
import 'element-ui/lib/theme-chalk/index.css';
import { Table, TableColumn, Popover, Icon, Message, Progress, Tooltip } from 'element-ui';
import * as echarts from 'echarts/core';
// Echart按需引入
import {
  ToolboxComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  GraphicComponent,
  GridComponent,
  VisualMapComponent,
  MarkAreaComponent,
  GeoComponent
} from 'echarts/components';
import {
  BarChart,
  PieChart,
  LineChart,
  MapChart,
  PictorialBarChart, EffectScatterChart, ScatterChart
} from 'echarts/charts';
import {
  CanvasRenderer
} from 'echarts/renderers';
echarts.use(
  [BarChart, PieChart, LineChart, MapChart, PictorialBarChart, EffectScatterChart, ScatterChart, ToolboxComponent, TitleComponent, TooltipComponent, LegendComponent, DatasetComponent, GraphicComponent, GridComponent, CanvasRenderer, VisualMapComponent, MarkAreaComponent, GeoComponent]
);
moment.locale('zh-cn'); // 设置语言 
Vue.prototype.$moment = moment;
Vue.config.productionTip = false
Vue.prototype.$moment = moment;
Vue.prototype.$echarts = echarts
Vue.prototype.$message = Message;
Vue.prototype.$md5 = md5;

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Icon);
Vue.use(Progress);
Vue.use(Tooltip)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
