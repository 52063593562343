import axios from 'axios'
import qs from 'qs'
import router from '../router'
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from 'element-ui'
export function request (config) {
  // 1、创建axios的实例
  const instance = axios.create({
    // baseURL: '/api',
    baseURL: 'https://pump.ahsanen.com/',
    timeout: 5000,
    headers: {
      get: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
      },
      post: {
        'Content-Type': 'application/json;charset=utf-8'
        // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
      }
    },
    // transformRequest: [function (data) {
    //   return qs.stringify(data)
    // }
    // ],
    paramsSerializer (data) {
      return qs.stringify(data)
    }
  });

  // 设置请求次数，请求的间隙
  instance.defaults.retry = 4;
  instance.defaults.retryDelay = 1000;

  // 2、axios的拦截器 请求拦截
  instance.interceptors.request.use(config => {
    // console.log("config", config);//发送成功拦截
    return config;
  }, error => {
    console.log('error', error); // 发送失败拦截
  });
  // 2.2 响应拦截
  instance.interceptors.response.use(res => {
    return res.data;
  }, error => {
    if (error.response) {
      // console.log('err' + error) // for debug
      switch (error.response.status) {
        case 401:
          // console.log('err status' + error.response.status)
          router.push('/login')
          break
        case 404:
          break
        case 500:
          break
      }
      return Promise.reject(error)
    } else {
      Message.closeAll()
      Message.warning('超时连接,正在重连');
      // 处理超时的情况
      const config = error.config
      // If config does not exist or the retry option is not set, reject
      if (!config || !config.retry) return Promise.reject(error)

      // Set the variable for keeping track of the retry count
      config.__retryCount = config.__retryCount || 0

      // Check if we've maxed out the total number of retries
      if (config.__retryCount >= config.retry) {
        // Reject with the error
        return Promise.reject(error)
      }

      // Increase the retry count
      config.__retryCount += 1

      // Create new promise to handle exponential backoff
      const backoff = new Promise(function (resolve) {
        setTimeout(function () {
          resolve()
        }, config.retryDelay || 1)
      })

      // Return the promise in which recalls axios to retry the request
      return backoff.then(function () {
        return instance(config)
      })
    }
  }
  );

  // 3、发送网络请求
  return instance(config);
}
